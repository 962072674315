import React from 'react'
import PropTypes from 'prop-types'
// CSS
import './App.css'

import Header from './components/Header'
import Admin from './components/Admin'

import withFirebase from './hoc/withFirebase'

import ColorContext from './components/Color'

// import fond from "./img/sociedad.jpg"


const App = ({match, datas, ajouterData, majData, supprimerData, totalPersonnes, total, data, totalAdultes, totalEnfants }) => {

  return (

    <ColorContext>
      <div className='box'>
        <Header pseudo={match.params.pseudo} />

        <Admin
          pseudo={match.params.pseudo}
          datas={datas}
          ajouterData={ajouterData}
          majData={majData}
          supprimerData={supprimerData}
          totalPersonnes={totalPersonnes}
          total={total}
          totalAdultes={totalAdultes}
          totalEnfants={totalEnfants}
          />
      </div>
      <footer>
        <div>Xipitei ©2019 Created by Batbakarrik V1.0</div>
      </footer>
    </ColorContext>
  )
}

App.propTypes = {
  match: PropTypes.object.isRequired,
  datas: PropTypes.object.isRequired,
  total: PropTypes.object.isRequired,
  ajouterData: PropTypes.func.isRequired,
  majData: PropTypes.func.isRequired,
  supprimerData: PropTypes.func.isRequired,
  chargerExemple: PropTypes.func.isRequired,
  totalPersonnes: PropTypes.func.isRequired,
  // totalAdultes: PropTypes.object.isRequired,
  // totalEnfants: PropTypes.object.isRequired,
}

const WrappedComponent = withFirebase(App)

export default WrappedComponent
