import React from 'react'

import { ColorContext } from './Color'

const Header = () => {

  return (
    <ColorContext.Consumer>
      {context => (
        <header style={{ backgroundColor: context.state.color }}>
          <h1>SOCIEDAD URKO</h1>
          <h4>Comida del 25/05/2019 en Arribe</h4>
        </header>
      )}
    </ColorContext.Consumer>
  )
}

export default Header
