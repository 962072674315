import React from 'react'

const Card = ({ details }) => {

  return (
    <div className='box'>
      <div className='cardgen'>
        <div className='data'>
          <ol className='liste-data1'>{details.nom}</ol>
          <ol className='liste-data'>{details.nbr1}</ol>
          <ol className='liste-data'>{details.nbr2}</ol>
        </div>
      </div>
    </div>
  )
}

export default Card
