import React from 'react'

const AdminForm = ({id: key, majData, datas, supprimerData, pasafficher, click}) => {
  const data = datas[key]

  const handleChange = (event, key) => {
    const { name, value } = event.target
    const data = datas[key]
    data[name] = value
    majData( key, data)
  }

  click = () => {
    pasafficher()
    supprimerData(key)
  }

  return (
    <div className='card' >
        <div>Editar-Aldaketaren-Modifier</div>
      <form className='admin-form'>
        <input value={data.nom} onChange={e => handleChange(e, key)} type='text' name='nom' placeholder='Apodo/Ezizena/Pseudo' />
        <textarea value={data.nbr1} onChange={e => handleChange(e, key)} name='nbr1' rows='1' placeholder='Numero de adultos?/Helduen kopurua?/Nombes d adultes' />
        <textarea value={data.nbr2} onChange={e => handleChange(e, key)} name='nbr2' rows='1' placeholder='Numero de niños?/Ume kopurua?/Nombes d enfants' />
      </form>
        {/* <button onClick={click}>Eliminar / Kendu</button> */}
    </div>
  )
}

export default AdminForm
