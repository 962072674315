import React, { Component } from 'react'

// Firebase
import base from '../base'
import datas from '../datas'
import Loading from '../components/Loading'

import firebase from 'firebase/app'
import 'firebase/auth'


const withFirebase = WrappedComponent => (
  class HOC extends Component {
    state = {
      pseudo: this.props.match.params.pseudo,
      loading: false,
      datas: {},
      data:{},
      color: '#bada55',
      total: {},
    }
    //Connexion avec firebase et emplacement des données
    componentDidMount () {
      this.ref = base.syncState(`/25052019/${this.state.pseudo}/datas`, {
        context: this,
        state: 'datas'
      })
    }
    //Connexion avec firebase pour copier toutes les données
    componentWillMount () {
      const ref = firebase.database().ref('/25052019/').orderByKey()
      ref.on('value', snapshot => {
        this.setState({
          total: snapshot.val()
        })
      })
      setTimeout(() => {
        this.setState({
          loading: false
        })
      }, 100);
    }

    //Déconnecton de firebase lorsque on change de page
    componentWillUnmount () {
      base.removeBinding(this.ref)
    }

    ajouterData = data => {
      const datas = { ...this.state.datas } //On copie le state
      // datas[`data`] = data
      datas[`data`] = data //Pour utiliser une cef unique
      this.setState({ datas })
    }

    majData = (key, newDatas) => {
      const datas = { ...this.state.datas }
      datas[key] = newDatas
      this.setState({ datas })
    }
    
    supprimerData = key => {
      const datas = { ...this.state.datas }
      datas[key] = null
      this.setState({ datas, dataOn : false })
    }
    
    chargerExemple = () => this.setState({ datas })
    
    totalPersonnes = () => {
      const total = { ...this.state.total };
      const totalAdultes = Object.keys(total).reduce((acc, key) =>
      acc + total[key].datas.data.nbr1 * 1,0);
      this.setState({ totalAdultes })
      const totalEnfants = Object.keys(total).reduce((acc, key) =>
      acc + total[key].datas.data.nbr2 * 1,0);
      this.setState({ totalEnfants })
    }
    
    render () {
      // const tot = Object.keys(this.state.total)
      // .map(key => <cards details={this.state.total[key].datas.data}></cards>)
      // console.log(tot)
      // this.setState({ tot })
    if (this.state.loading) {
      return <Loading />
    }
      return (
        <WrappedComponent
          datas={this.state.datas}
          total={this.state.total}
          ajouterData={this.ajouterData}
          majData={this.majData}
          supprimerData={this.supprimerData}
          chargerExemple={this.chargerExemple}
          totalPersonnes={this.totalPersonnes}
          totalAdultes={this.state.totalAdultes}
          totalEnfants={this.state.totalEnfants}
          {...this.props} />
          )
    }
  }

)

export default withFirebase
