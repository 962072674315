import Rebase from 're-base'
import firebase from 'firebase/app'
import 'firebase/database'

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyBfPOihEzRclXSuUN7ntQ1NEecfwFraBCE",
  authDomain: "sociedad-urko.firebaseapp.com",
  databaseURL: "https://sociedad-urko.firebaseio.com",
})

const base = Rebase.createClass(firebaseApp.database())

export { firebaseApp }

export default base
