import React, { Component } from 'react'
import AdminForm from './AdminForm'
import Login from './Login'
import { Redirect } from 'react-router-dom'
import firebase from 'firebase/app'
import 'firebase/auth'
import base, { firebaseApp } from '../base'
import Loading from './Loading'
import Total from '../components/Total'
import Card from '../components/Card'

class Admin extends Component {
  state = {
    uid: null, //id personne connectée
    dataid: null, //id du chef des data
    // dataOn: null,
    goToApp: false,
    loading: true,
  }
  
  componentDidMount () {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.handleAuth({ user })
      }
    })
  }
  
  componentDidUpdate(prevProps) {
    if (this.state.uid) {
    if (prevProps.total !== this.props.total) {
      this.props.totalPersonnes();
    }
  }
}
  componentWillMount () {
    setTimeout(() => {
      this.setState({
        loading: false
      })
    }, 5000);
  }
  // Afficher sur condition
  // handleShow =() => {
  //   const dataOn = !this.props.datas.data
  //   this.setState({ dataOn })
  // }

  handleAuth = async authData => {
    //On récupère le pseudo dans firebase
    const box = await base.fetch(this.props.pseudo, { context: this })
    //Si chef n'excite pas on lui attribut uid
    if (!box.dataid) {
      await base.post(`/25052019/${this.props.pseudo}/dataid`, {
        data: authData.user.uid
      })
    }
    if (!box.dataid) {
      await base.post(`/25052019/${this.props.pseudo}/dataidemail`, {
        data: authData.user.email
      })
    }
    if (!box.dataid) {
      await base.post(`/25052019/${this.props.pseudo}/dataiddisplayName`, {
        data: authData.user.displayName
      })
    }
    if (!box.dataid) {
      await base.post(`/25052019/${this.props.pseudo}/datas/data/nbr1`, {
        data: '0'
      })
    }
    if (!box.dataid) {
      await base.post(`/25052019/${this.props.pseudo}/datas/data/nbr2`, {
        data: '0'
      })
    }
    if (!box.dataid) {
      await base.post(`/25052019/${this.props.pseudo}/datas/data/nom`, {
        data: `${this.props.pseudo}`
      })
    }
    //On met à jour le state
    this.setState({
      uid: authData.user.uid, //On attribut uid dans le state
      dataid: box.dataid || authData.user.uid, //Si box.chef n'existe pas on récupère uid
    })
  }
  authenticateFacebook = async () => {
    const authProvider = await new firebase.auth.FacebookAuthProvider()
    firebaseApp
      .auth()
      .signInWithRedirect(authProvider)
      .then(this.handleAuth)
  }

  authenticateTwitter = async () => {
    const authProvider = await new firebase.auth.TwitterAuthProvider()
    firebaseApp
      .auth()
      .signInWithRedirect(authProvider)
      .then(this.handleAuth)
  }

  authenticateGoogle = async () => {
    const authProvider = await new firebase.auth.GoogleAuthProvider()
    firebaseApp
      .auth()
      .signInWithRedirect(authProvider)
      .then(this.handleAuth)
  }

  logout = async () => {
    await firebase.auth().signOut()
    this.setState({ uid: null })
  }

  afficher = () => {
    this.setState({ dataOn: true })
  }

  pasafficher = () => {
    this.setState({ dataOn: false })
  }

  goToApp = event => {
    event.preventDefault()
    this.setState({ goToApp: true })
  }

  render () {
    const { datas, majData, supprimerData, total, totalAdultes, totalEnfants } = this.props

    const logout = <button onClick={this.logout}>Desconexión-Desconektazeko</button>
    
    if (this.state.loading) {
      return <Loading />
    }
    
    if (this.state.goToApp) {
      return <Redirect push to={``} />
    }
    
    // Si l'utilisateur n'est pas connecté
    if (!this.state.uid) {
      return (
        <div>
        <div className='cards'>
          <div className='box'>
            <div className='cardgen'>
              <div className='data'>
                <ol className='liste-data1'>Menu:</ol>
                <ol className='liste-data'></ol>
              </div>
              <div className='data'>
                <ol className='liste-data'></ol>
                <ol className='liste-data1'>Ensalada Mixta, Paté</ol>
              </div>
              <div className='data'>
                <ol className='liste-data'></ol>
                <ol className='liste-data1'>Axoa con Arroz</ol>
              </div>
              <div className='data'>
                <ol className='liste-data'></ol>
                <ol className='liste-data1'>Iparalde Tarta</ol>
              </div>
              <div className='data'>
                <ol className='liste-data'></ol>
                <ol className='liste-data1'>Café, txotx</ol>
              </div>
              <br></br>
              <div className='data'>
                <ol className='liste-data'>Animacíon:</ol>
                <ol className='liste-data1'></ol>
              </div>
              <div className='data'>
                <ol className='liste-data'></ol>
                <ol className='liste-data1'>Juegos (Uno, Mus, Football)</ol>
              </div>
              <br></br>
            </div>
          </div>
        </div>
          <Login authenticateFacebook={this.authenticateFacebook} authenticateTwitter={this.authenticateTwitter} authenticateGoogle={this.authenticateGoogle} />
          <div className='login'>
            <form className='connexion' onSubmit={this.goToApp} >
              <button type='submit'>Salir-Irteera-Sortir</button>
            </form>
          </div>
      </div>
      )
    }
    // Si l'utilisateur ne correspont pas à uid on renvoi message
    if (this.state.uid !== this.state.dataid) {
      return (
        <div>
          <p>Tu n'es pas l'admin de cette page' !</p>
          {logout}
        </div>
      )
    }
    const cards = Object.keys(total)
    .map((key, i) => <Card key={i} details={total[key].datas.data}></Card>)

    // const card = Object.keys(datas)
    // .map((key, i) => <Card key={i} details={datas[key]}></Card>)


    return (
        <div className='cards'>
          <div className='box'>
            <div className='cardgen'>
              <div className='data'>
                <ol className='liste-data1'>Apodo</ol>
                <ol className='liste-data'>Adulto</ol>
                <ol className='liste-data'>Niños</ol>
              </div>
            </div>
          </div>

          { cards }

          <Total
            total={total}
            datas={datas}
            totalAdultes={totalAdultes}
            totalEnfants={totalEnfants}
          />
          {/* <br></br>
          <div className='box'>
            <div className='cardgen'>
              <div className='data'>
              <ol className='liste-data1'>Apodo</ol>
              <ol className='liste-data'>Adulto</ol>
              <ol className='liste-data'>Niños</ol>
              </div>
            </div>
          </div>

          { card } */}
          {/* {!dataOn ? <AjouterData
              ajouterData={this.props.ajouterData}
              afficher={this.afficher}
              totalPersonnes={this.props.totalPersonnes}
              /> : <div></div>
            } */}
          <div>
            {Object.keys(datas)
              .map(key => <AdminForm
                key={key}
                id={key}
                majData={majData}
                supprimerData={supprimerData}
                datas={datas}
                pasafficher={this.pasafficher}
                />
              )
            }
            </div> 
            <footer>
              {logout}
              {/* <button onClick={chargerExemple}>Remplir</button> */}
            </footer>
      </div>
    )
  }
}
      
export default Admin
