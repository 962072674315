import React from 'react'

const Login = ({ authenticateFacebook, authenticateTwitter, authenticateGoogle }) => {
  return (
    <div className='login'>
      <div className='card'>
          <h4>Para registrarse - Izena emateko - Pour s'inscrire</h4>
          <h5>Iniciar sesión con - Konektatu - Me connecter avec</h5>
        <div className='iconlogin'>
          <button onClick={authenticateFacebook} className='loginBtn loginBtn--facebook'>
          Facebook
          </button>
          <button onClick={authenticateGoogle} className='loginBtn loginBtn--google'>
          Google
          </button>
          <button onClick={authenticateTwitter} className='loginBtn loginBtn--twitter'>
          Twitter
          </button>
        </div>
      </div>
    </div>
  )
}

export default Login
