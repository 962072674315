// Une liste pour remplir directement ta boîte !!
const datas = {
  data1: {
    nom: 'Frank',
    nbr1: '',
    nbr2: '',
  },
}

export default datas
