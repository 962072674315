import React from 'react'
import ReactLoading from 'react-loading'

const Loading = () => (
    <div className='Loading'>
        <ReactLoading
            type='cylon'
            color='#000'
        />
    </div>
)

export default Loading