import React from 'react'

const Total = ({totalAdultes, totalEnfants}) => {

        return (
            <div className='box'>
                <div className='cardgen'>
                    <div className='data'>
                        <ol className='liste-data1'>Total:</ol>
                        <ol className='liste-data'>{totalAdultes}</ol>
                        <ol className='liste-data'>{totalEnfants}</ol>
                    </div>
                </div>
            </div>
        )

}
export default Total
